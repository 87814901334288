import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import * as AWSCognito from "amazon-cognito-identity-js";

import { environment } from 'src/environments/environment';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class CognitoService {

  constructor( private http:HttpClient ) { 
    const SESConfig = {
      region: 'ap-northeast-1',
      credentials: new AWS.Credentials(environment.accessKeyId, environment.secretAccessKey)
    }
    AWS.config.update(SESConfig);
  }


  singIn(email,password){
    const userPool = new AWSCognito.CognitoUserPool(environment.cognito_data);
    const authDetails = new AWSCognito.AuthenticationDetails({
      Username: email,
      Password: password
    });

    const cognitoUser = new AWSCognito.CognitoUser({
      Username: email,
      Pool: userPool
    });
    const observable = new Observable((observer => {  // 追加
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: result => {
          observer.next(result);
        },
        onFailure: err => {
          observer.error("ログイン情報が誤っています");
        },
        newPasswordRequired: userAttributes => {
          console.log(userAttributes);
          observer.next("newpass");
        }  
      });
    }));
    
    return observable;
  }

  newUserPass(email, oldpass, newpass){
    const userPool = new AWSCognito.CognitoUserPool(environment.cognito_data);
    const authDetails = new AWSCognito.AuthenticationDetails({
      Username: email,
      Password: oldpass
    });

    const cognitoUser = new AWSCognito.CognitoUser({
      Username: email,
      Pool: userPool
    });

    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    var params = {
      UserPoolId: 'ap-northeast-1_D1vbp9Pm5', /* required */
      Username: email,
      UserAttributes: [ /* required */
        {
          Name: 'email_verified', /* required */
          Value: 'true'
        }
      ]
    };
    

    const observable = new Observable((observer => {  // 追加
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: result => {},
        onFailure: err => {},
        newPasswordRequired: userAttributes => {
          cognitoUser.completeNewPasswordChallenge(newpass,null,{
            onFailure : function(err) {
              observer.error("エラーが発生しました");
            },
            onSuccess : function(result) {
              cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function(err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else{
                  console.log(data);           // successful response
                  observer.next(result);
                }     
              });
         
            }
        });
        }  
      });
    }));
    
    return observable;

  }
  resetPass(email){
    const userPool = new AWSCognito.CognitoUserPool(environment.cognito_data);

    const cognitoUser = new AWSCognito.CognitoUser({
      Username: email,
      Pool: userPool
    });

    return new Observable((observer => { 
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          console.log(data);
        },
        onFailure: (err) => {
          observer.error(err);
        },
        inputVerificationCode: (data) => {
          //console.log("Code sent to:" + data);
          console.log("hoge1");
          observer.next(data);
          // cognitoUser.confirmPassword(verificationCode, newPassword, {
          //   onSuccess: () => {
          //     console.log('Password confirmed!');
          //     gAppendMessage('Resetting password succeeded!');
          //   },
          //   onFailure: (err) => {
          //     console.log('Password not confirmed!');
          //     gAppendMessage('Resetting password failed.');
          //   }
          // });
        }
      });
    }));
  }



  verifyPass(code, email,pass){
    const userPool = new AWSCognito.CognitoUserPool(environment.cognito_data);

    const cognitoUser = new AWSCognito.CognitoUser({
      Username: email,
      Pool: userPool
    });

    return new Observable((observer => { 
      cognitoUser.confirmPassword(code, pass, {
        onSuccess() {
          console.log('Password confirmed!');
          observer.next("confirmed");
        },
        onFailure(err) {
          observer.error(err);
        },
      });
    }));

  }

  updateAttribute(email, pass, attribute){
    var attributeList = [];
    const userPool = new AWSCognito.CognitoUserPool(environment.cognito_data);
    let userData = {
      Username: email,
      Pool: userPool
    }
    var attributes = new AWSCognito.CognitoUserAttribute(attribute);
    attributeList.push(attributes);


    const authDetails = new AWSCognito.AuthenticationDetails({
      Username: email,
      Password: pass
    });

    const cognitoUser = new AWSCognito.CognitoUser({
      Username: email,
      Pool: userPool
    });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: result => {
        console.log("バックでログイン");
        cognitoUser.updateAttributes(attributeList, function(err, result) {
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          }
          console.log('call result: ' + result);
        });
      },
      onFailure: err => {
      }
    });

    // this.backsigIn(email, pass).

    // cognitoUser.updateAttributes(attributeList, function(err, result) {
    //   if (err) {
    //     alert(err.message || JSON.stringify(err));
    //     return;
    //   }
    //   console.log('call result: ' + result);
    // });
  }


  activeUser(){
    let Data;
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    var params = {
      UserPoolId: 'ap-northeast-1_D1vbp9Pm5', /* required */
      "Filter": "status = \"Enabled\""
      // "Filter": "status = \"Disabled\""
    };
    const observable = new Observable((observer => {
      cognitoidentityserviceprovider.listUsers(params, function(err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else{
          //console.log(data);           // successful response
          observer.next(data);
        }     
      })
    }));
    
    return observable;
  }
  nonactiveUser(){
    let Data;
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    var params = {
      UserPoolId: 'ap-northeast-1_D1vbp9Pm5', /* required */
      "Filter": "status = \"Disabled\""
    };
    const observable = new Observable((observer => {
      cognitoidentityserviceprovider.listUsers(params, function(err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else{
          //console.log(data);           // successful response
          observer.next(data);
        }     
      })
    }));
    
    return observable;
  }

  disableUser(Username){
    let Data;
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    var params = {
      UserPoolId: 'ap-northeast-1_D1vbp9Pm5', /* required */
      Username : Username
    };

    const observable = new Observable((observer => {
      cognitoidentityserviceprovider.adminDisableUser(params, function(err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else     console.log(data);           // successful response
      });
    }));
    
    return observable;
  }

  eableUser(Username){
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    var params = {
      UserPoolId: 'ap-northeast-1_D1vbp9Pm5', /* required */
      Username : Username
    };
    const observable = new Observable((observer => {
      cognitoidentityserviceprovider.adminEnableUser(params, function(err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else     console.log(data);           // successful response
      });
    }));
    
    return observable;
  }
  attributeChange(email, att){
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    var params = {
      UserPoolId: 'ap-northeast-1_D1vbp9Pm5', /* required */
      Username: email,
      UserAttributes: att
    };
    const observable = new Observable((observer => {
      cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function(err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else{
          //console.log(data);           // successful response
          observer.next(data);
        }     
      })
    }));
    
    return observable;
  }



  // userRegister(email, domain){
  //   var attributeList = [];
  //   const userPool = new AWSCognito.CognitoUserPool(environment.cognito_data);
  //   console.log(environment.cognito_data);
  //   let userData = {
  //     Username: email,
  //     Pool: userPool
  //   }

  //   var attributeList = [];

  //   var dataEmail = {
  //     Name: 'email',
  //     Value: email,
  //   };

  //   var dataAtt = [ 
  //     {
  //       Name: 'custom:domain',
  //       Value: domain,
  //     },
  //     {
  //       Name: 'custom:permission',
  //       Value: "user",
  //     }
  //   ];
  //   var attributeEmail = new AWSCognito.CognitoUserAttribute(dataEmail);
  //   var attribute1 = new AWSCognito.CognitoUserAttribute(
  //     dataAtt[0]
  //   );

  //   var attribute2 = new AWSCognito.CognitoUserAttribute(
  //     dataAtt[1]
  //   );
  //   attributeList.push(attributeEmail);
  //   attributeList.push(attribute1);
  //   attributeList.push(attribute2);

  //   userPool.signUp(email , "hoge1208aaa", attributeList, null, function(
  //     err,
  //     result
  //   ) {
  //     if (err) {
  //       alert(err.message || JSON.stringify(err));
  //       return;
  //     }
  //     var cognitoUser = result.user;
  //     console.log('user name is ' + cognitoUser.getUsername());
  //   });
  // }
  
}
