import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { CognitoService } from './services/cognito.service';
import { MemoServerApiService  }  from './services/memo-server-api.service';
import { StorageService } from './services/storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private cognitoService : CognitoService,
    private router:Router,
    private storage:StorageService,
    private memoapp:MemoServerApiService

  ) { }
  async isAuthenticated(){
    flag : Boolean;
    //return of(this.storage.getName('mail'));
    return await this.storage.getName('mail').then(
      (value) => {
        //console.log("現在のメール",value)
        if (value != null && value !== ""){

          this.storage.getName('agreement').then(
            (version)=>{
              console.log("v", version);
              if (version == null || version == ""){
                this.router.navigate(['/agreement']);
              }
            }
          );

          this.storage.getName('token').then(
            (token) => {
              this.memoapp.tokenCheck(token).subscribe(
                (res:string) =>{
                  let status = res["message"];
                  if(status == "success"){
                    console.log(res);
                  }
                  else if(status == "メンテナンス"){
                    alert(res["detail"]);
                    this.router.navigate(['/login']);
                    return false;
                  }
                  else{
                    this.retryAuth();
                  }
      
                }
              );
            }
          );
          return true;
        }
        

        else{
          this.storage.getName('slides').then(
            (value) => {
              if (value != "done"){
                this.router.navigate(['/slides']);
              }else{
                this.router.navigate(['/login']);
                return false;
              }
            }
          );
          
          // return false;
        }
        
      }
    );
    
    //return true;
  }

  // APIのリクエストにこけたとき、再認証させる
  retryAuth(){
    let mail;
    let pass;
    this.storage.getName('mail').then(
      (res) => {
        mail = res;
        this.storage.getName('pass').then(
          (res) =>{
            pass = res;
            this.cognitoService.singIn(mail, pass).subscribe(
              res => {
                // console.log(JSON.stringify(res));
                console.log(res);
                // res = res["idToken"];
                
                this.storage.setName('token', res["accessToken"]["jwtToken"]);
                this.storage.setName('domain', res["idToken"]["payload"]["custom:domain"]);
                this.storage.setName('permission', res["idToken"]["payload"]["custom:permission"]);
                this.storage.setName('token', res["accessToken"]["jwtToken"]);
                window.location.reload();
              },
              err => {
                // Login pageへ飛ばす
                this.router.navigate(['/login']);
              }
            );
          }
        )
      }
    );
    

  }
}
