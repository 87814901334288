import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'tab4',
    loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule)
  },
  {
    path: 'tab5',
    loadChildren: () => import('./tab5/tab5.module').then( m => m.Tab5PageModule)
  },
  {
    path: 'tab6',
    loadChildren: () => import('./tab6/tab6.module').then( m => m.Tab6PageModule)
  },
  {
    path: 'tab7',
    loadChildren: () => import('./tab7/tab7.module').then( m => m.Tab7PageModule)
  },
  {
    path: 'newpass',
    loadChildren: () => import('./modal/newpass/newpass.module').then( m => m.NewpassPageModule)
  },
  {
    path: 'newpass',
    loadChildren: () => import('./modal/newpass/newpass.module').then( m => m.NewpassPageModule)
  },
  {
    path: 'password/reset',
    loadChildren: () => import('./password/reset/reset.module').then( m => m.ResetPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'visual-perform',
    loadChildren: () => import('./visual-perform/visual-perform.module').then( m => m.VisualPerformPageModule)
  },
  {
    path: 'new-create',
    loadChildren: () => import('./new-create/new-create.module').then( m => m.NewCreatePageModule)
  },
  {
    path: 'infolist',
    loadChildren: () => import('./infolist/infolist.module').then( m => m.InfolistPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'info-view',
    loadChildren: () => import('./modal/info-view/info-view.module').then( m => m.InfoViewPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./modal/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'sectors',
    loadChildren: () => import('./sectors/sectors.module').then( m => m.SectorsPageModule)
  },
  {
    path: 'admin/home',
    loadChildren: () => import('./admin/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'admin/add-user',
    loadChildren: () => import('./admin/add-user/add-user.module').then( m => m.AddUserPageModule)
  },
  {
    path: 'admin/users',
    loadChildren: () => import('./admin/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'sector-edit',
    loadChildren: () => import('./admin/sector-edit/sector-edit.module').then( m => m.SectorEditPageModule)
  },
  {
    path: 'slides',
    loadChildren: () => import('./slides/slides.module').then( m => m.SlidesPageModule)
  },
  {
    path: 'view-image',
    loadChildren: () => import('./modal/view-image/view-image.module').then( m => m.ViewImagePageModule)
  },
  {
    path: 'admin/edit-sector',
    loadChildren: () => import('./admin/edit-sector/edit-sector.module').then( m => m.EditSectorPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-sectors',
    loadChildren: () => import('./modal/add-sectors/add-sectors.module').then( m => m.AddSectorsPageModule)
  },
  {
    path: 'admin/edit-sector-detail',
    loadChildren: () => import('./admin/edit-sector-detail/edit-sector-detail.module').then( m => m.EditSectorDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'newinfo',
    loadChildren: () => import('./newinfo/newinfo.module').then( m => m.NewinfoPageModule)
  },
  {
    path: 'agreement',
    loadChildren: () => import('./agreement/agreement.module').then( m => m.AgreementPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'demo-applepay',
    loadChildren: () => import('./demo-applepay/demo-applepay.module').then( m => m.DemoApplepayPageModule)
  },
  {
    path: 'add-app',
    loadChildren: () => import('./add-app/add-app.module').then( m => m.AddAppPageModule)
  },
  {
    path: 'detail-sales-menu',
    loadChildren: () => import('./modal/detail-sales-menu/detail-sales-menu.module').then( m => m.DetailSalesMenuPageModule)
  },
  {
    path: 'admin/export-data',
    loadChildren: () => import('./admin/export-data/export-data.module').then( m => m.ExportDataPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  // imports: [ RouterModule.forRoot(routes, { useHash: true }) ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
