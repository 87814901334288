import { Component } from '@angular/core';

import { Platform } from "@ionic/angular";
import { StatusBar, Style } from '@capacitor/status-bar';

import { Storage } from '@ionic/storage-angular';

import { isPlatform } from '@ionic/angular';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor( 
    private platform : Platform,
    private storage: Storage
  ) {
    this.initializeApp();
  }
  
  initializeApp(　) {
    if(! isPlatform("mobileweb") ){
      this.platform.ready().then(() => {
        StatusBar.setStyle({ style: Style.Light });
        //StatusBar.hide();
      });
    }
  }

}
