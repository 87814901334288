// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  region: 'ap-northeast-1',
  API_URL: "https://dev-memo-api.development-morita.work/" ,
  accessKeyId : "AKIASXNCY6U3SXBLWXGS",
  secretAccessKey : "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",
  cognito_data :{
    UserPoolId: "ap-northeast-1_D1vbp9Pm5",
    ClientId: "3ilan9uj2b9dpe9f8ugch8t68d"
  },
BucketName:"memo-app-bucket"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


