import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemoServerApiService {

  URL = environment.API_URL;
  constructor( 
    private httpclient: HttpClient
  ) {
    
   }



  getRequest(token, path, hash){ //, path
    const paramsOptions = <HttpParamsOptions>{fromObject: hash};
    
    const option = {
         headers: new HttpHeaders(
          {
            Accept: 'application/json', 
            Authorization : token
          }
        ),
        params : new HttpParams(paramsOptions)
      };
    return this.httpclient.get(this.URL+path , option
    );
  }

  postRequest(token, path, temp){
    const body = JSON.stringify(temp);
    const option = {
         headers: new HttpHeaders(
          {
            Accept: 'application/json', 
            Authorization : token,
            'Content-Type':  'application/json'
          }
        )
    };
    return this.httpclient.post(this.URL+path, body, option
    );
  }

  putRequest(token, path, temp){
    const body = JSON.stringify(temp);
    const option = {
         headers: new HttpHeaders(
          {
            Accept: 'application/json', 
            Authorization : token,
            'Content-Type':  'application/json'
          }
        )
    };
    // console.log(this.URL+path, body, option);
    return this.httpclient.put(this.URL+path, body, option
    );
  }



  getCounter():Observable<string>{
    const observe = this.httpclient.get(this.URL+"counter");
    // filterで奇数のみを抽出後、mapで二乗する
    const squareOddVals = pipe(
        map( (data:any) => data.num ),
        filter((n: number) => n % 2 !== 0),
        map( (n: number)  => `現在奇数です ${n}` )
    );
    const squareOdd = squareOddVals(observe);
    return squareOdd;

  }

  // sample用
  getSamples(token){
    console.log(token);
    const path = "view/sectors";
    const param = {
      days: 60000,
      mode: "not-complete"
    };
    return this.getRequest(token, path, param);
  }

  // 検索する関数
  searchClient(token){
    // const path = "view/sectors";
    // return this.getRequest(token, path);
  }
  // SectorMeta情報
  SectorMeta(token){
    const path = "sector_meta";
    const param = {
      id: "all"
    };
    return this.getRequest(token, path, param);
  }
  // SectorMetaALL情報
  SectorMetaALL(token){
    const path = "sector_meta";
    const param = {
      id: "allAll"
    };
    return this.getRequest(token, path, param);
  }
  // homeの新規情報
  homeNew(token){
    const path = "view/sectors";
    const param = {
      days: 14
    };
    return this.getRequest(token, path, param);
  }
  // homeの未対応
  homeNot(token){
    const path = "view/sectors";
    const param = {
      mode: "not-complete"
    };
    return this.getRequest(token, path, param);
  }

  // 部門シェア取得
  SectorShare(token, period){
    const path = "view/graph";
    const param = {
      "sectors": {
        "period" :period
      }
    };
    return this.postRequest(token, path, param);
  }

  // 売り上げシェア取得
  SalesShare(token, period, sector_id){
      const path = "view/graph";
      const param = {
        "sales": {
          "period" :period,
          "sector_id":sector_id
        }
      };
      return this.postRequest(token, path, param);
  }
    // 売り上げシェア詳細
    SalesSharedetail(token, sector_id, start, stop){
      const path = "view/graph";
      const param = {
        "sales": {
          "sector_id":sector_id,
          "start":start,
          "stop":stop
        },

      };
      return this.postRequest(token, path, param);
  }

  // 顧客情報の詳細
  detailClient(token, id){
    const path = "client";
    const param = {
      id : id
    };
    return this.getRequest(token, path, param);

  }

  // token check
  tokenCheck(token){
    const path = "who";
    const param = {};
    return this.getRequest(token, path, param);
  }

  // 顧客情報の更新
  updateClient(token, newData){
    const path = "client";
    return this.putRequest(token, path, newData);
  }

  // 部門別情報を取得
  getSectorDetail(token){
    const path = "sector_detail";
    const param = {
      all: "true"
    };
    return this.getRequest(token, path, param);
  }

  // 顧客情報の全取得
  allClent(token){
    const path = "client/search";
    const param = {
      mode: "about",
      query: ""
    };
    return this.getRequest(token, path, param);
  }

  // 業種情報を取得
  getKindDetail(token){
    const path = "industry_meta";
    const param = {
      all: "true"
    };
    return this.getRequest(token, path, param);
  }

  // 事業種別を取得
  getBusinessKindDetail(token){
    const path = "business_kind";
    const param = {
      all: "true"
    };
    return this.getRequest(token, path, param);
  }

  // sectorのステータス変更
  updateSector(token, data){
      const path = "sector";
      return this.putRequest(token, path, data);
  }

  // 顧客情報の登録
  postClent(token, data){
    const path = "client";
    return this.postRequest(token, path, data);
  }


  // ユーザの登録
  createUser(token, data){
    const path = "api/register";
    return this.postRequest(token, path, data);
  }

  // PDFのパスを発行
  getPDFpath(token, id){
    const path = "pdf_print/exec";
    const param = {
      id : id
    };
    return this.getRequest(token, path, param);

  }

  // 契約終了間近を取得
  getNearContract(token){
    const path = "view/sectors";
    const param = {
      mode: "near-contract",
      days: 30*2
    };
    return this.getRequest(token, path, param);
  }
  // 新規商材情報を取得
  getNewInfomation(token){
    const path = "view/sectors";
    const param = {
      mode: "new-information"
    };
    return this.getRequest(token, path, param);
  }
  

  // 部門情報の登録
  postSectors(token, data){
    const path = "sector_meta";
    return this.postRequest(token, path, data);
  }


  // 部門情報の更新
  putSectors(token, data){
    const path = "sector_meta";
    return this.putRequest(token, path, data);
  }
  
    // 部門詳細情報の登録
    postSectorDetail(token, data){
      const path = "sector_detail";
      return this.postRequest(token, path, data);
    }
  
  
    // 部門詳細の更新
    putSectorDetail(token, data){
      const path = "sector_detail";
      return this.putRequest(token, path, data);
    }


  // 部門詳細情報を取得
  getDetails(token, id){
    const path = "sector_detail";
    const param = {
      "search_id": id
    };
    return this.getRequest(token, path, param);
  }

  // 新規商材部門詳細情報を取得
  getNewDetails(token, id){
    const path = "sector_detail";
    const param = {
      "new_id": id
    };
    return this.getRequest(token, path, param);
  }

    // 添付部門詳細情報を取得
    getPdfDetails(token){
      const path = "sector_detail";
      const param = {
        "pdf_all": "all"
      };
      return this.getRequest(token, path, param);
    }


  
  createUserDev(email){
    const data = {
      "email":email
    };
    const path = "create_review";
    return this.postRequest("", path, data
    );
  }

  checkAppStatus(){
    const path = "app_status_check";
    const param = {
      "version": "v1.1"
    };
    return this.getRequest("", path, param);
  }

  // ユーザのDB情報アップデート
  putUser(token, data){
    const path = "user";
    return this.putRequest(token, path, data);
  }

  export_data(token, data){
    const path = "export";
    // let data = {
    //   "sector_id":1,
    //   "start":"2020-01-01 00:00:00",
    //   "stop":"2023-01-01 00:00:00"
    // };
    return this.postRequest(token, path, data);
  }

}
