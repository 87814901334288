import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(

  ) { 
    
  }

  async setName (key, value){

    await Storage.set({
      key: key,
      value: value,
    });
    this.getData (key).then(
      res => console.log(res)
    );
    //console.log("セットしたよ",);
  };
  
  async getName (key) {
    const { value } = await Storage.get( {key: key} );
    return value;
  };
  
  async removeName(key) {
    await Storage.remove(
      {key:key}
    );
  };

  setData(key, value){
    return new Promise((resolve, reject) => {
      this.setName (key, value).then(
        ()=>{
          return resolve('完了！')
        }
      );
    });
  }

  getData(key){
    return new Promise((resolve, reject) => {
      this.getName (key).then(
        (res)=>{
          //return resolve(res+"です！");
          return resolve(res);
        }
      );
    });
  }

}

