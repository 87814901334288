import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { TabHeaderComponent } from '../components/tab-header/tab-header.component';



@NgModule({
  declarations: [TabHeaderComponent],
  imports: [
    CommonModule
  ],
  exports: [TabHeaderComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class SharedModule { }
